<template>
  <v-layout row justify-center>
    <v-dialog v-model="component.destroyDialog" persistent max-width="500">
      <v-card>
        <v-card-title>Deseja Excluir este item?</v-card-title>
        <v-card-text
          >Essa ação irá excluir o item do {{ getTitle() || "" }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="
              () => {
                executeAlterDestroyDialog(this);
              }
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="success ml-4"
            @click="
              () => {
                submit(this);
              }
            "
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { SPLICE_ERRO } from '../../../store/actions/alert_api';
import { DESTROY_TAX_OPERATION_TYPE } from '../../../store/actions/tax_operation_types';


export default {
  props: {
    component: Object,
    destroy_object: Function,
  },
  methods: {
    executeAlterDestroyDialog() {
      this.component.destroyDialog = false;
    },
    submit() {
      this.executeAlterDestroyDialog();
      // this.destroy_object(this.component.currentItem);
      this.$store.dispatch(DESTROY_TAX_OPERATION_TYPE, this.component.currentItem.id).then((response) => {
        if (response.code >= 200 && response.code <= 299) {
          const index = this.component.results.findIndex((element) => element.id == response.id)
          this.component.results.splice(index, 1)
        } else {
          this.$store.commit(SPLICE_ERRO, {
            alert: 'Erro ao deletar o item',
            type: "E"
          })
        }
        this.component.GetAll()
      })
    },



    getTitle() {
      return (
        (this.component.component && this.component.component.title) || "teste"
      );
    },
  },
};
</script>

<style>
</style>
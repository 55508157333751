<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      class="mb-3"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog
      :component="this"
      :destroy_paymentTerm="destroy_paymentTerm"
    />
  </div>
</template>

<script>
import DestryDialog from "./../destroyDialog/index";
import {
  AUX_TO_UPDATE_PAYMENTTERM,
  GET_ALL_PAYMENT_TERM,
} from "../../../store/actions/paymentterm";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_paymentTerm: Function,
    search: String,
    headers: Array,
    open_Dialog: Function,
  },

  components: { DestryDialog },

  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),

  methods: {
    visibleItem(item) {
      this.$store.dispatch(AUX_TO_UPDATE_PAYMENTTERM, {
        item: item,
        justVisible: true,
      });
      this.component.addDialog = true;
    },

    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store
        .dispatch(GET_ALL_PAYMENT_TERM, value)
        .then((response) => {
          this.results = response.data;
          this.total = response.last_page;
          this.current = response.current_page;
        });
    },
  },

  created() {
    this.GetAll();
  },
  computed: {
    ...mapGetters(["getPaymentTerms"]),
  },
  watch: {
    getPaymentTerms: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if(this.component.search.length >= 2){
        this.component.paymentTerm_search && this.component.paymentTerm_search(val);
      }else{
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>

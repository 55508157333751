<template>
    <Container>
      <v-card>
        <v-card-text
          ><CardTitle :downTitle="changeDialog" :title="Title" :component="this"
        /></v-card-text>
        <v-layout>
          <Info :component="this" />
        </v-layout>
        <AddDialog
          :dialog="dialog"
          :changeDialog="changeDialog"
          :submit="submit"
        />
        <v-card-text>
          <v-text-field
            outlined
            label="Pesquisar"
            append-icon="mdi-magnify"
            v-model="searchInput"
            @keyup.enter="setAutocomplete()"
          ></v-text-field>
        </v-card-text>
        <Table :component="this" :header="header" :items="table_downtitle" />
      </v-card>
      <AlertAPI />
      <LoadScreen />
    </Container>
  </template>
  
  <script>
  import AlertAPI from "../components/alert_api/alert_api";
  import Container from "../components/Utils/Container/Container";
  import CardTitle from "../components/TitleReversal/CardTitle/";
  import Info from "../components/TitleReversal/Info/";
  import Table from "../components/TitleReversal/Table/";
  import AddDialog from "../components/TitleReversal/AddDialog/";
  import LoadScreen from "../components/load/index.vue";
  import {
    GET_BILLS_PAY_BY_STATUS,
    AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS,
    BILLS_PAY_REVERSAL,
    GET_ALL_BILLS_PAY_BY_DATE
  } from "../store/actions/bills_pay";
  import {
    GET_BILLS_RECEIVE_BY_STATUS,
    AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS,
    BILLS_RECEIVE_REVERSAL,
    GET_ALL_BILLS_RECEIVE_BY_DATE,
  } from "../store/actions/bills_receive"; 
  import { GET_ALL_CASHIER } from "../store/actions/cashier";
  import { mapGetters } from "vuex";
  export default {
    components: {
      Container,
      CardTitle,
      Info,
      Table,
      AddDialog,
      AlertAPI,
      LoadScreen,
    },
    data: () => ({
      Title: "Estorno de Títulos",
      valid: Boolean(),
      cashier: [],
      id_cashier: Number(),
      date: {
        date_initial: String(),
        date_final: String(),
      },
      current: Number(),
      total_table: Number(),
      total: Number(),
      dialog: Boolean(),
      enterpriseId: Number(),
      table_downtitle: Array(),
      downtitle: Array(),
      search: String(),
      searchInput: String(),
      header: [
        { text: "Status", value: "status" },
        { text: "Tipo do Título", value: "type_title" },
        { text: "Data de Emissão", value: "issuance_date" },
        { text: "Data de Vencimento", value: "issuance_due" },
        { text: "Número do Título", value: "number_title" },
        { text: "Valor do Título", value: "value_title" },
        { text: "Valor Líquido", value: "title_balance" },
        { text: "Estornar?", value: "down", align: "center" },
      ],
      selectedItems: Array(),
      downTitleValid: true,
      buttonLabel: "Estornar",
      titleType: "Títulos a Pagar",
      titleTypeSelectItems: ["Títulos a Pagar", "Títulos a Receber"],
      dateSearch: Boolean(),
    }),
    computed: {
      ...mapGetters(["getBillPaysByStatus", "getCashier", "getAutoCompleteBillsToPay", "getBillReceive"]),
    },
    methods: {
      setAutocomplete() {
        this.search = this.searchInput
      },
      
      async autoCompleteByStatus(page = 1) {
          let payload = {
            search: this.search,
            page: page,
            limit: 10,
            paginate: true,
            enterprise_id: this.enterpriseId,
            status: 'Baixado',
          };
          if(this.titleType == "Títulos a Pagar") {
            await this.$store.dispatch(AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS, payload);
          } else {
            await this.$store.dispatch(AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS, payload);
          }
      },
  
      async GetBills_Pay(page = 1) {
        let value = {
          status: "Baixado",
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_BILLS_PAY_BY_STATUS, value);
      },

      async GetBills_Receive(page = 1) {
        let value = {
          status: "Baixado",
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_BILLS_RECEIVE_BY_STATUS, value);
      },
  
      async GetAllNoPage() {
        let value = {
          page: null,
          limit: null,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_CASHIER, value);
      },
  
  
      async submit() {
        try {
          let itemsData = Array()
          for(let i=0; i<this.selectedItems.length; i++) {
            itemsData.push({
              number_title:  this.selectedItems[i].number_title,
              type_title: this.selectedItems[i].type_title,
              enterprise_id: this.selectedItems[i].enterprise_id,
              account_plan_id: this.selectedItems[i].account_plans_id
            })
          }
          const payload = {
            reversal_data: itemsData
          }
            if(this.titleType == "Títulos a Pagar") {
                this.$store.dispatch(BILLS_PAY_REVERSAL, payload);
            }else {
                this.$store.dispatch(BILLS_RECEIVE_REVERSAL, payload);
            }
        } catch (error) {
          alert(error);
        } finally {
          this.selectedItems = []
          this.downtitle = []
          this.total = 0
          this.changeDialog();
          this.titleType = "Títulos a Pagar",
          this.GetBills_Pay()
        }
      },
      changeDialog() {
        this.dialog = !this.dialog;
      },
      find(page = 1) {
        this.dateSearch = true
        let payload = {
          page: page,
          paginate: true,
          limit: 10,
          initial_date: this.date.date_initial,
          final_date: this.date.date_final,
          enterprise_id: this.enterpriseId,
          status: 'Baixado',
        };
        if(this.titleType == "Títulos a Pagar") {
          this.$store.dispatch(GET_ALL_BILLS_PAY_BY_DATE, payload);
        } else {
          this.$store.dispatch(GET_ALL_BILLS_RECEIVE_BY_DATE, payload);
        }
      },

      cancelFind() {
      this.dateSearch = false
      if(this.titleType == "Títulos a Pagar") {
        this.GetBills_Pay()
      } else {
        this.GetBills_Receive()
      }
    },
  
      current_Date() {
        var dNow = new Date();
        var month = dNow.getMonth() + 1;
        var dec, dFull;
  
        if (month < 10) {
          month = "0" + month;
        }
  
        if (dNow.getDate() <= 9) {
          dec = "0";
  
          dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
          this.date.date_initial = dFull;
          this.date.date_final = dFull;
        } else {
          dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
          this.date.date_initial = dFull;
          this.date.date_final = dFull;
        }
      },
    },
    watch: {
      search: function (val) {
        if(val.length>=2) {
          this.autoCompleteByStatus()
        } else if(this.titleType == "Títulos a Pagar") {
            this.GetBills_Pay()
        }else {
            this.GetBills_Receive()
        }
      },

      searchInput: function (val) {
        if(val.length == 0 && this.titleType == "Títulos a Pagar") {
          this.GetBills_Pay()
          this.search = ''
        } else if(val.length == 0 && this.titleType == "Títulos a Receber") {
          this.GetBills_Receive()
          this.search = ''
        }
      },

      getBillPaysByStatus:function(val) {
        this.total_table = val.last_page;
        this.current = val.current_page;
        this.table_downtitle = val.data;
      },

      getBillReceive: function(val) {
        this.total_table = val.data.last_page;
        this.current = val.data.current_page;
        this.table_downtitle = val.data.data;
      },
  
      current: function (val) {
        this.selectedItems = []
        if(this.searchInput.length>=2) {
          this.autoCompleteByStatus(val)
        } else if(this.dateSearch) {
            this.find(val)
        } else {
          if(this.titleType == "Títulos a Pagar") {
            this.GetBills_Pay && this.GetBills_Pay(val)
          } else {
            this.GetBills_Receive && this.GetBills_Receive(val);
          }
        }
      },
  
      getCashier: function (val) {
        this.cashier = Object.keys(val).map((key) => val[key]);
      },
  

      titleType: function (val) {
        this.selectedItems = []
        if(val == "Títulos a Pagar") {
            this.GetBills_Pay()
        }else {
            this.GetBills_Receive()
        }
      },
      selectedItems: function (val) {
        if(val.length >= 1) {
            this.downTitleValid = false
        } else {
            this.downTitleValid = true
        }
      }
    },
    created() {
      this.GetAllNoPage();
      this.GetBills_Pay();
      this.current_Date();
    },
  };
  </script>
  
  <style>
  </style>
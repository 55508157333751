import { SPLICE_ERRO } from '../../../store/actions/alert_api';
import { DESTROY_PRODUCT_GROUP, GET_ALL_PRODUCT_GROUP } from '../../../store/actions/productgroup'
import { CHANGE_LOAD } from '../../../store/actions/load'

async function destroy_productGroup(item) {
    const payload = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_PRODUCT_GROUP, payload).then((response) => {

            if (response.code >= 200 && response.code <= 299) {
                const index = this.component.results.findIndex((element) => element.id == response.id)
                this.component.results.splice(index, 1)
                commit(SPLICE_ERRO, {
                    alert: 'Deletado com Sucesso',
                    type: 'S'
                })
            } else {
                this.$store.commit(SPLICE_ERRO, {
                    alert: 'Não Foi possível Excluir o item',
                    type: 'E',
                })
            }
        });
    } catch (error) {
        //alert(error);
    }
    this.GetAll()
}
function close_DestroyDialog() {
    this.component.destroyDialog = false;
}
function submit() {
    this.destroy_productGroup(this.component.currentItem)
    this.close_DestroyDialog();
    // this.destroy_productGroup(this.component.currentItem);
}
function getTitle() {
    return (
        (this.component.component && this.component.component.title) || "teste"
    );
}

async function GetAll(page = 1) {
    this.$store.commit(CHANGE_LOAD, true);
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
}


export default {
    destroy_productGroup,
    close_DestroyDialog,
    submit,
    getTitle,
    GetAll
}
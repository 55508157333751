<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Sem registros"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item)
                }
              "
            >mdi-eye</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              v-on="on"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
            >mdi-pencil</v-icon>
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
            >mdi-delete</v-icon>
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <div>
      <v-divider></v-divider>
      <v-pagination class="mb-6" v-model="current" :length="total" color="success"></v-pagination>
    </div>
    <destroyDialog :component="this" :destroy_object="destroy_object" />
    <!-- <AliquotDialog /> -->
  </div>
</template>

<script>
// import { AUX_TO_UPDATE_ALIQUOT } from "../../../store/actions/aliquot";
import UiBusinessRules from "../../../UIBusinessRule/Aliquot/Table";
import destroyDialog from "./../destroyDialog/";
// import AliquotDialog from "../AddDialog/index.vue";
import { mapGetters } from "vuex";

export default {
  comments: {
    // AliquotDialog
  },
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String,
    headers: Array
    // change_addDialog: Function,
  },
  components: { destroyDialog },
  data: () => ({
    destroyDialog: false,
    currentItem: Object(),
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object()
  }),
  methods: {
    ...UiBusinessRules
  },

  created() {
    this.component.GetAll();
  },
   watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function(val) {
      if (this.component.search.length >= 2) {
        this.component.aliquot_filter && this.component.aliquot_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    }
  }
};
</script>

<template>
  <container>
   <v-card class="ma-5">
      <CardTitle
        actionsShowDialog="setShowDialogRegisterCorrectionLetter"
        title="Carta de Correção de Nota Fiscal"
      />
      <v-text-field
          label="Pesquisar"
          color="success mr-2 mt-3"
          outlined
          class="ma-3 mt-10"
          append-icon="mdi-magnify"
          @keyup.enter="setAutocomplete()"
          v-model="searchInput"
      />
      <Table 
        :headers="headers"  
        :component="this" 
        :lengthPaginator="lengthPaginator" 
      />
      <AddDialog
        v-if="getShowDialogRegisterCorrectionLetter"
        :component="this"
      />
      <LoadScreen/>
      <AlertApi/>
    </v-card>
  </container>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise";
import {GET_ALL_CORRETION_NFE, AUTOCOMPLETE_CORRECTION_LETTER} from "../store/actions/correction_letter"
import CardTitle from "../components/correction_letter/CardTitle";
import Table from "../components/correction_letter/Table";
import tableHeaders from "./headers_tables/Correction_letter.js";
import AddDialog from "../components/correction_letter/AddDialog";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
import AlertApi from "../components/alert_api/alert_api"
export default {
  name: "CorrectionLetter",
  components: {
    CardTitle,
    Table,
    AddDialog,
    LoadScreen,
    AlertApi
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterCorrectionLetter","getEnterprises"]),
  },
  data: function () {
    return {
      headers: tableHeaders,
      lengthPaginator: Number(),
      selectValue: Object(),
      correctionLetterDataToView: Object(),
      justView: false,
      searchInput: String(),
      search: String(),
      disablePagination: false
    };
  },
  methods: {
    async getAllEnterprise(page=1) {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: page,
        limit: 100,
        paginate: true,
      });
    },
    getAllCorrectionNfe(page=1) {
      this.disablePagination = true
      this.$store.dispatch(GET_ALL_CORRETION_NFE, {
        cnpjEmitter: this.selectValue.cnpj,
        page: page,
        limit: 10,
        paginate: true,

      })

    },
    calculateNumberPage(itemQuantity) {
      if (itemQuantity % 5 > 1) {
        this.lengthPaginator = itemQuantity / 5 + 1;
      } else {
        this.lengthPaginator = itemQuantity / 5;
      }
    },

    viewCorrectionLetter(correctionToView) {
      this.correctionLetterDataToView = correctionToView
      this.justView = true
      this.$store.dispatch("setShowDialogRegisterCorrectionLetter", true);
    },

    setAutocomplete(){
      this.search = this.searchInput
    },

    autocomplete(page=1){
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
        search: this.search,
        cnpjEmitter: this.selectValue.cnpj
      }   
      this.$store.dispatch(AUTOCOMPLETE_CORRECTION_LETTER, payload)
    }
  },
  watch: {
    getEnterprises(val) {
      this.selectValue = val.data[0];
      this.getAllCorrectionNfe();
    },

    searchInput: function (val) {
      if(val.length == 0) {
        this.getAllCorrectionNfe();
        this.search = ""
      }
    },

    search:function(val) {
      if(val.length >= 2) {
        this.autocomplete()
      }
    }
  },
  async created() {
    await this.getAllEnterprise();
    
  },
};
</script>

<style>
</style>
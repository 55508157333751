<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="setAutocomplete()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :change_addDialog="change_addDialog"
        :search="search"
        :destroy_object="destroy_object"
      />
      <AddDialog :component="this" v-if="addDialog" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/cashier_type_operation/CardTitle/";
import Table from "../components/cashier_type_operation/Table/";
import AlertApi from "../components/alert_api/alert_api";
import AddDialog from "../components/cashier_type_operation/AddDialog/index";
import loadScreen from "../components/load/";

import {
  DESTROY_CASHIER_OPERATION_TYPE,
  GET_FILTER_CASHIER_OPERATION_TYPE,
  AUX_TO_UPDATE_CASHIER_OPERATION_TYPE,
  GET_ALL_CASHIER_OPERATION_TYPE,
} from "../store/actions/cashier_operation_type";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    getter: "getCashierOperationType",
    addDialog: false,
    destroyDialog: false,
    filter: "",
    idToDestroy: 0,
    objectToUpdate: {},
    title: "Operação de caixa",
    permission: Object(),
  }),
  computed: { ...mapGetters(["getCadastrosLinks"]) },

  methods: {
    async change_addDialog(item) {
      this.addDialog = !this.addDialog;
      // this.objectToUpdate = item;
      this.$store.dispatch(AUX_TO_UPDATE_CASHIER_OPERATION_TYPE, item);
    },

    async destroy_object(item) {
      const cashier_operation = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(
          DESTROY_CASHIER_OPERATION_TYPE,
          cashier_operation
        );
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_CASHIER_OPERATION_TYPE, value);
    },

    async cashierOperationType_filter(page = 1) {
      let value = {
        search: this.search,
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_CASHIER_OPERATION_TYPE, value);
    },

    setAutocomplete() {
      this.search = this.searchInput
    },
  },

  components: {
    Container,
    CardTitle,
    Table,
    loadScreen,
    AlertApi,
    AddDialog,
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length >= 2){
        this.cashierOperationType_filter()
      }
    }
  },
};
</script>



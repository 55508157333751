<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="setAutocomplete()"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        v-if="permission.Visualizar"
        :getters="getProductGroup"
        
        :search="search"
        :headers="headers"
        :open_editDialog="open_editDialog"
      />
      <ModalProductGroup v-if="getProductGroup" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/productgroup/CardTitle/index";
import Table from "../components/productgroup/Table/index";
import ModalProductGroup from "../components/productgroup/AddDialog/index";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";
import UIBusinessRule from "../UIBusinessRule/Product_group/View/View"
import { mapGetters } from "vuex";



export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    getter: "getProductGroup",
    addDialog: false,
    destroyDialog: false,
    enterpriseId: "",
    enterprises: [],
    filter: "",
    selectValue: Object(),
    idToDestroy: 0,
    objectToUpdate: {},
    title: "Grupo de Produto",
    permission: Object(),
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "name" },
      { text: "Ações", value: "actions" },
    ],
  }),

  methods: {
    ...UIBusinessRule
  },

  async created() {
    // await this.GetAll();
  },

  components: {
    Container,
    Table,
    ModalProductGroup,
    AlertApi,
    loadScreen,
    CardTitle,
  },

  computed: {
    ...mapGetters(["getProductGroup","getCadastrosLinks",]),
    
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },

  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
    if(val.length >= 2){
        this.productgroup_filter()
      }
    }
    
  },
};
</script>

<style scoped></style>

import { ADD_ALIQUOT, UPDATE_ALIQUOT } from "../../store/actions/aliquot";

function closeAliquotDialog() {
  this.$refs.form.reset()
  this.$store.dispatch("setShowDialogRegisterAliquot", false);
  this.aliquot = String();
  this.description = String();
  this.justVisible = false
  this.id = undefined;
}

function getComponent() {
  return this.component;
}

async function create_aliquot() {
  const aliquot = {
    description: this.description,
    aliquot: this.aliquot,
  };
  this.$store.dispatch("setShowDialogLoading", true);
  await this.$store.dispatch(ADD_ALIQUOT, aliquot).then(() => {
    this.$store.dispatch("setShowDialogLoading", false);
    this.component.GetAll();
    this.closeAliquotDialog();
  });
}

function submit() {
  this.id == undefined ? this.create_aliquot() : this.update_aliquot();
}

async function update_aliquot() {
  const aliquot = {
    id: this.id,
    description: this.description,
    aliquot: this.aliquot,
  };

  await this.$store.dispatch(UPDATE_ALIQUOT, aliquot).then(() => {
    this.component.GetAll();
    this.closeAliquotDialog();
  });
}

function show_aliquot(aliquot) {
  this.aliquot = aliquot.aliquot;
  this.description = aliquot.description;
}

function mountedObject() {
  this.object = { ...this.getComponent().objectToUpdate };
  this.id = this.object.id;
  this.description = this.object.description;
  if (this.id >= 0) {
    this.aliquot = this.object.aliquot.toString();
  } else {
    this.aliquot = this.object.aliquot;
  }
}

export default {
  closeAliquotDialog,
  getComponent,
  create_aliquot,
  submit,
  update_aliquot,
  show_aliquot,
  mountedObject,
};

import { ADD_CST, UPDATE_CST, GET_ALL_CST } from "../../../store/actions/cst";




function autoLoad(val) {

    if (val.justVisible) {
        this.justVisible = val.justVisible;
        this.code = val.item.code;
        this.description = val.item.description;
    } else {
        this.code = val.code;
        this.created_at = val.created_at;
        this.description = val.description;
        this.id = val.id;
        this.updated_at = val.updated_at;
    }
}
function reset() {
    this.$refs.form.reset();
}
function resetValidation() {
    this.$refs.form.resetValidation();
}
function closeCst() {
    this.$store.dispatch("setShowDialogRegisterCST", false);
    this.reset();
    this.resetValidation();
    this.justVisible = false
}
function getComponent() {
    return this.component;
}
async function create_cst() {
    const cst = {
        code: this.code,
        description: this.description,
    };
    await this.$store.dispatch(ADD_CST, cst).then(() => {
        this.GetAll();
        this.closeCst();
    });
}
function submit() {
    this.id == undefined ? this.create_cst() : this.update_cst();
}
async function update_cst() {
    const cst = {
        id: this.id,
        code: this.code,
        description: this.description,
    };
    await this.$store.dispatch(UPDATE_CST, cst).then(() => {
        this.GetAll();
        this.closeCst();
    });
}
async function GetAll(page = 1) {
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_CST, value);
}





export default {
    autoLoad,
    reset,
    resetValidation,
    closeCst,
    getComponent,
    create_cst,
    submit,
    update_cst,
    GetAll
}
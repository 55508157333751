import { ADD_CFOP, UPDATE_CFOP } from "../../../store/actions/cfop";




function close_Cfop() {
    this.$refs.form.reset()
    this.$store.dispatch("setShowDialogRegisterCFOP", false);
    this.code = "";
    this.created_at = "";
    this.description = "";
    this.id = undefined;
    this.updated_at = "";
    this.justVisible = false
}
function getComponent() {
    return this.component;
}
function submit() {
    this.id == undefined ? this.create_cfop() : this.update_cfop();
}
async function create_cfop() {
    // this.getFiscalType = undefined;
    const cfop = {
        code: this.code,
        description: this.description,
    };
    await this.$store.dispatch(ADD_CFOP, cfop).then(() => {
        this.component.GetAll();
        this.close_Cfop();
    });
}
async function update_cfop() {
    const cfop = {
        id: this.id,
        code: this.code,
        description: this.description,
    };
    await this.$store.dispatch(UPDATE_CFOP, cfop).then(() => {
        this.component.GetAll();
        this.close_Cfop();
    });
}
function validate() {
    this.$refs.form.validate();
}




export default {
    close_Cfop,
    getComponent,
    submit,
    create_cfop,
    update_cfop,
    validate,
}
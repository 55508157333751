<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="setAutocomplete()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getPaymentTerms"
        :destroy_paymentTerm="destroy_paymentTerm"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalPaymentTerm v-if="addDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadsScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/paymentterm/CardTitle/index";
import Table from "../components/paymentterm/Table/index";
import ModalPaymentTerm from "../components/paymentterm/AddDialog/index";
import loadsScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";

import { mapGetters } from "vuex";
import {
  GET_ALL_PAYMENT_TERM,
  DESTROY_PAYMENT_TERM,
  GET_FILTER_PAYMENT_TERM,
  AUX_TO_UPDATE_PAYMENTTERM,
} from "../store/actions/paymentterm";

export default {
  data: () => ({
    permission: Object(),
    search: "",
    searchInput: "",
    filter: "",
    code: "",
    getter: "getPaymentTerms",
    addDialog: false,
    destroyDialog: false,
    objectToUpdate: {},
    title: "Condição Pagamento",
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Parcela", value: "amount" },
      { text: "Prazo", value: "deadline" },
      { text: "Ações", value: "actions" },
    ],
  }),

  methods: {
    async destroy_paymentTerm(item) {
      const paymentterm = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_PAYMENT_TERM, paymentterm);
      } catch (error) {
        alert(error);
      }
    },

    open_Dialog(item) {
      this.addDialog = !this.addDialog;
      //this.objectToUpdate = item;
      this.$store.dispatch(AUX_TO_UPDATE_PAYMENTTERM, item);
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, value);
    },

    async paymentTerm_search(page = 1) {
      let value = {
        search: this.search,
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_PAYMENT_TERM, value);
      //}
    },

    setAutocomplete() {
      this.search = this.searchInput
    }
  },

  async created() {
    this.GetAll();
  },

  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  components: {
    Container,
    CardTitle,
    Table,
    loadsScreen,
    ModalPaymentTerm,
    AlertApi,
  },

  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
  if(val.length >= 2){
        this.paymentTerm_search()
      }
    }
  },

  computed: { ...mapGetters(["getPaymentTerms", "getCadastrosLinks"]) },
};
</script>

<style scoped></style>

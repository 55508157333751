<template>
  <v-row justify="center">
    <v-dialog :retain-focus="false" persistent v-model="getShowDialogRegisterProductGroup" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Grupo de Produto</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" class="mt-6">
            <v-col>
              <v-text-field
                v-model="code"
                :readonly="justVisible"
                type="number"
                maxlength="4"
                label="*Código"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => String(v).length >= 4 || 'Campo com 4 caracteres'
                ]"
                outlined
              />
              <v-text-field
                v-model="name"
                :readonly="justVisible"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                label="*Nome"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="cancel">
                <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar
              </v-btn>
              <v-btn
                v-if="!justVisible"
                color="success"
                :disabled="!valid"
                class="mr-4"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { mapGetters } from "vuex";
import UIBusinessRule from "../../../UIBusinessRule/Product_group/AddDialog/AddDialog";
export default {
  props: {
    groupProductGetAll: Boolean(),
    component: Object(),
    OpenDialog: Boolean(),
    EnterPriseID: Object()
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterProductGroup", "getObjectToUpdate"])
  },
  name: "productGroup-dialog", 
  data() {
    return {
      justVisible: Boolean(),
      CallDialog: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      code: Number(),
      name: String(),
      cfopToUpdate: "",
      search: "",
      formHasErrors: false
    };
  },

  methods: {
    ...UIBusinessRule
  },

  watch: {
    // getObjectToUpdate: function (val) {
    //   console.log(val);
    //   console.log(val.item.code);
    //   console.log(this.name);


    //   if (val.justVisible) {
        
    //     this.justVisible = val.justVisible;
    //     this.code = val.item.code;
    //     this.name = val.item.name;
    //     console.log(this.name);
    //     this.name = 'a'
    //   } else {
    //     this.code = this.getObjectToUpdate.code;
    //     this.name = this.getObjectToUpdate.name;
    //     this.id = this.getObjectToUpdate.id;
    //   }
    // },
  },

  async created() {
    this.autoload(this.getObjectToUpdate)
  },

  beforeDestroy() {
    this.cleanObjectToUpdate()
  }
};
</script>

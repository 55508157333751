import { ADD_NCM, UPDATE_NCM, AUX_TO_UPDATE_NCM } from "../../../store/actions/ncm";
function closeNcmDialog() {
    this.component.addDialogNcm = false;
    this.justVisible = false;
  }
  function getComponent() {
    return this._props.component;
  }
  async function  create_ncm() {
    const ncm = {
      code: this.code,
      description: this.description,
    };
    await this.$store.dispatch(ADD_NCM, ncm).then(() => {
      this.component.GetAll();
      this.closeNcmDialog();
    });
  }
  function submit() {
    this.id == undefined ? this.create_ncm() : this.update_ncm();
  }
  async function  update_ncm() {
    const ncm = {
      id: this.id,
      code: this.code,
      description: this.description,
    };
    await this.$store.dispatch(UPDATE_NCM, ncm).then(() => {
      this.component.GetAll();
      this.closeNcmDialog();
    });
  }

  function autoload(payload) {
    if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.description = payload.item.description;
        this.code = payload.item.code;
    } else {
        this.description = payload.description;
        this.code = payload.code;
        this.id = payload.id;
    }
}


export default  {
    closeNcmDialog,
    getComponent,
    create_ncm,
    submit,
    update_ncm,
    autoload,
}
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item)
                }
              "
            >mdi-eye</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      class="mb-6"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_cfop="destroy_cfop" />
    <!-- <CFOPDialog v-if="getShowDialogRegisterCFOP" :component="this" /> -->
  </div>
</template>

<script>

import DestryDialog from "./../destroyDialog/index";
// import CFOPDialog from "../AddDialog/";
import { mapGetters } from "vuex";
import UiBusinessRules from "../../../UIBusinessRule/CFOP/Table/Table"
export default {
  computed: {
    ...mapGetters(["getShowDialogRegisterCFOP","getCfopToAdd"]),
  },
  comments: {
    // CFOPDialog,
  },
  props: {
    component: Object,
    getters: Object,
    destroy_cfop: Function,
    search: String,
    headers: Array,
  },
  components: { DestryDialog },
  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),
  methods: {
    ...UiBusinessRules
  },
  created() {
    this.component.GetAll();
  },
  watch: {
    getCfopToAdd: function(){
      this.results.push(this.getCfopToAdd)
    },
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.search.length >= 2) {
        this.component.cfop_filter && this.component.cfop_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>

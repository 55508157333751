import { ADD_CASHIER, UPDATE_CASHIER, AUX_TO_UPDATE_CASHIER } from "../../../store/actions/cashier";

function getComponent() {
    return this.component;
}

function cancel() {
    this.component.addDialog = false;
    this.justVisible = false
    this.$store.dispatch(AUX_TO_UPDATE_CASHIER, null)
}

async function create_cashier() {
    const cashier = {
        code: this.code,
        description: this.description,
        initial_balance: this.initial_balance,
        balance_current: 0,
        initial_date: this.initial_date,
        cashierType: this.cashierType,
    };

    await this.$store.dispatch(ADD_CASHIER, cashier).then(() => {
        this.cancel();
    });
}

function submit() {
    this.id == null ? this.create_cashier() : this.update_cashier();
}

function dataAtualFormatada(date) {
    var data = new Date(date);
    let dia = (data.getDate() + 1).toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
}

async function update_cashier() {
    const cashier = {
        id: this.id,
        code: this.code,
        description: this.description,
        initial_balance: this.initial_balance,
        balance_current: this.balance_current,
        initial_date: this.initial_date.split("/").reverse().join("-"),
        cashierType: this.cashierType,
    };

    await this.$store.dispatch(UPDATE_CASHIER, cashier).then(() => {
        this.cancel();
    });
}

function autoload(payload) {
    if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.description = payload.item.description;
        this.balance_current = payload.item.balance_current;
        this.code = payload.item.code;
        this.initial_balance = payload.item.initial_balance;
        this.initial_date = payload.item.initial_date;
        this.cashierType = payload.item.cashierType;
    } else {
        this.description = payload.description;
        this.balance_current = payload.balance_current;
        this.code = payload.code;
        this.initial_balance = payload.initial_balance;
        this.initial_date = payload.initial_date;
        this.cashierType = payload.cashierType;
        this.id = payload.id;
    }
}

export default {
    getComponent,
    cancel,
    create_cashier,
    submit,
    dataAtualFormatada,
    update_cashier,
    autoload,
}
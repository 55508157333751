<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="setAutocomplete()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getCompanygroup"
        :destroy_companyGroup="destroy_companyGroup"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalCompanyGroup :showDialog="showDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>


<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/companygroup/CardTitle/index";
import Table from "../components/companygroup/Table/index";
import ModalCompanyGroup from "../components/companygroup/AddDialog/index";
import loadScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";

import { mapGetters } from "vuex";
import {
  GET_ALL_COMPANY_GROUP,
  DESTROY_COMPANY_GROUP,
  GET_FILTER_COMPANY_GROUP,
} from "../store/actions/companygroup";

export default {
  data: () => ({
    showDialog: Boolean(),
    search: "",
    searchInput: "",
    code: "",
    getter: "getCompanygroup",
    addDialog: false,
    objectToUpdate: {},
    filter: "",
    title: "Grupo de Empresas",
    permission: Object(),
    headers: [
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
  }),

  async created() {
    this.GetAll();
  },

  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  methods: {
    async destroy_companyGroup(item) {
      const car_adm = {
        id: item.id,
      };
      await this.$store.dispatch(DESTROY_COMPANY_GROUP, car_adm);
    },
    open_Dialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_COMPANY_GROUP, value);
    },

    async companyGroup_filter(page = 1) {
        let value = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_COMPANY_GROUP, value);
    },

    setAutocomplete() {
      this.search = this.searchInput
    }
  },

  components: {
    ModalCompanyGroup,
    Container,
    CardTitle,
    Table,
    loadScreen,
    AlertApi,
  },

  watch: {
    getShowDialogRegisterGroupEnterprise: function () {
      if (this.getShowDialogRegisterGroupEnterprise == false) {
        this.showDialog = false;
      }
    },
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
  if(val.length >= 2){
        this.companyGroup_filter()
      }
    }
  },

  computed: {
    ...mapGetters([
      "getCompanygroup",
      "getCadastrosLinks",
      "getShowDialogRegisterGroupEnterprise",
    ]),
  },
};
</script>


<style scoped>
</style>
import { GET_ALL_CST, DESTROY_CST } from "../../../store/actions/cst";
import { GET_FILTER_CST } from "../../../store/actions/cst";




async function destroy_cst(item) {
    const cst = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_CST, cst);
    } catch (error) {
        alert(error);
    }
}
function open_Dialog(item) {
    this.addDialogCst = !this.addDialogCst;
    this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_CST, value);
}
async function cst_filter(page = 1) {
        let value = {
            search: this.search,
            page: page,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_CST, value);
}

function setAutocomplete() {
    this.search = this.searchInput
  }


export default {
    destroy_cst,
    open_Dialog,
    GetAll,
    cst_filter,
    setAutocomplete
}
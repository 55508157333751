<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="getShowDialogRegisterTaxOperationType"
      max-width="700"
    >
      <v-card outlined>
        <v-card-title primary-title>Tipo de Operação Fiscal</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-col>
              <v-tabs class="success">
                <v-tab href="#tab-1">
                  <v-icon class="mr-2"> mdi-card-account-details </v-icon> Dados
                  Gerais</v-tab
                >
                <v-tab href="#tab-2"
                  ><v-icon class="mr-2">mdi-cash-check</v-icon
                  >Movimentação</v-tab
                >
                <v-tab-item value="tab-1">
                  <General ref="geral" :component="this" />
                </v-tab-item>

                <v-tab-item value="tab-2" :eager="true">
                  <Movement :component="this" />
                </v-tab-item>
              </v-tabs>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="closeDialog">
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Methods from "./helpers/Methods"
import General from "../tabs/General";
import Movement from "../tabs/Movement";
import { GET_ALL_CFOP } from "../../../store/actions/cfop";
import {
  ADD_TAX_OPERATION_TYPE,
  UPDATE_TAX_OPERATION_TYPE,
  AUX_TO_UPDATE_TAX_OPERATION_TYPE
} from "../../../store/actions/tax_operation_types";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    objectToUpdate: Object,
  },
  components: {
    General,
    Movement,
  },
  data: () => ({
    dialogCfop: false,
    items: [
      { value: "Não", key: 0 },
      { value: "Sim", key: 1 },
    ],
    types: [
      { text: "ENTRADA", value: 0 },
      { text: "SAIDA", value: 1 },
    ],
    liststatus: [
      { text: "ATIVO", value: "ATIVO" },
      { text: "INATIVO", value: "INATIVO" },
    ],

    valid: true,
    resultAliquot: null,
    search: String(),
    formHasErrors: false,
    auxTaxOperationType: String(),
    tax_operation_type: {
      id: undefined,
      status: String(),
      code: String(),
      type: String(),
      description: String(),
      normal_output: String(),
      output_of_state: String(),
      replacement: String(),
      calculate_tax: String(),
      move_stock: String(),
      move_financial: String(),
      feeds_cost: String(),
    },
    type: null,
    aux_normal_output: Object(),
    aux_output_of_state: Object(),
    aux_replacement: Object(),
    normal_output_text: String(),
    output_of_state_text: String(),
    replacement_text: String(),
    object: Object(),
    aux: String(),
    cfopFilterSelect: Array(),
    cfop: Array(),
  }),

  computed: {
    ...mapGetters([
      "getCfop",
      "getShowDialogRegisterTaxOperationType",
      "getItemToUpdateTaxOperationType",
    ]),
  },

  methods: {
    ...Methods,
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_CFOP, value);
    },
    closeDialog() {
      this.$store.dispatch(AUX_TO_UPDATE_TAX_OPERATION_TYPE, null); 
      this.$store.dispatch("setShowDialogRegisterTaxOperationType", false);
    },
    getComponent() {
      return this.component;
    },

    returnObjectForAction() {
      this.tax_operation_type.type = this.type;
      this.tax_operation_type.code = parseInt(this.tax_operation_type.code);
      this.tax_operation_type.normal_output = this.normal_output_text;
      this.tax_operation_type.output_of_state = this.output_of_state_text;
      this.tax_operation_type.replacement = this.replacement_text;
      let returnObject = { ...this.tax_operation_type };

      returnObject.calculate_tax = this.tax_operation_type.calculate_tax;
      returnObject.feeds_cost = this.tax_operation_type.feeds_cost;
      returnObject.move_stock = this.tax_operation_type.move_stock;
      returnObject.move_financial = this.tax_operation_type.move_financial;

      return returnObject;
    },

    async create_tax_operation_type() {
      const tax_operation_type = this.returnObjectForAction();

      await this.$store
        .dispatch(ADD_TAX_OPERATION_TYPE, tax_operation_type)
        .then(() => {
          this.component.GetAll()
          this.$store.dispatch("setShowDialogRegisterTaxOperationType", false);
        });
    },
    loadFieldsToUpdate(item) {
      this.tax_operation_type = { ...item };
      this.type = item.type == "ENTRADA" ?  1 : 2
      this.normal_output_text = item.normal_output.id
      this.output_of_state_text = item.output_of_state.id
      this.replacement_text = item.replacement.id
    },
    submit() {
      this.tax_operation_type.id == undefined
        ? this.create_tax_operation_type()
        : this.update_tax_operation_type();
    },

    async update_tax_operation_type() {
      const tax_operation_type = this.returnObjectForAction();
      await this.$store
        .dispatch(UPDATE_TAX_OPERATION_TYPE, tax_operation_type)
        .then(() => {
          this.wipeData()
          this.$store.dispatch(AUX_TO_UPDATE_TAX_OPERATION_TYPE, null);
          this.component.GetAll()
          this.$store.dispatch("setShowDialogRegisterTaxOperationType", false);
        });
    },

    createItemsCFOP(val) {
      this.cfop = Array();

      if (val == 1) {
        this.cfop = this.cfopFilterSelect.filter((e) => {
          if (e.code < "5000") {
            return e;
          }
        });
      } else if (val == 2) {
        this.cfop = this.cfopFilterSelect.filter((e) => {
          if (e.code >= "5000") {
            return e;
          }
        });
      }
    },
    // mountObjectUpdate() {
    //   this.tax_operation_type = { ...this.objectToUpdate };
    //   this.tax_operation_type.code = `${this.tax_operation_type.code}`;
    //   this.type = this.tax_operation_type.type == "ENTRADA" ? 1 : 2;
    //   this.normal_output_text = this.tax_operation_type.normal_output.id;
    //   this.output_of_state_text = this.tax_operation_type.output_of_state.id;
    //   this.replacement_text = this.tax_operation_type.replacement.id;
    // },
  },

  async created() {
    // this.wipeData()
    await this.GetAllNoPage();
    if(this.getItemToUpdateTaxOperationType){
      this.loadFieldsToUpdate(this.getItemToUpdateTaxOperationType)
    }
    // this.objectToUpdate.id == undefined || this.mountObjectUpdate();
  },

  watch: {
    getItemToUpdateTaxOperationType: function (val) {
      this.loadFieldsToUpdate(val);
    },

    type: function (val) {
      this.createItemsCFOP(val);
    },

    getCfop: function (val) {
      this.cfopFilterSelect = Object.values(val);
    },
  },

  beforeDestroy() {
    this.wipeData()
    // this.getComponent().objectToUpdate = Object();
  },
};
</script>